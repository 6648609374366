.react-trello-board > div,
.react-trello-lane > div{
    width: 100%;
    margin: 0px;
}

.smooth-dnd-container.vertical > div{
    margin-top: 10px;
}
.smooth-dnd-draggable-wrapper > article{
    margin: 0px !important;
    max-width: initial !important;
}
.smooth-dnd-draggable-wrapper section > div:nth-of-type(2){
    max-height: initial !important;
}
/*.smooth-dnd-draggable-wrapper{
    padding: 10px;
}
*/

/*.smooth-dnd-container.horizontal {
    white-space: normal;
}*/