
.ChooseImage{
    width: 100%;
    height: 200px;
    border: 1px solid #ced4da;
    font-size: 30px;
    cursor: pointer;
    border-radius: 0.25rem;
    color: #ced4da;
    position: relative;
    text-align: center;
}
.ChooseImage input{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    left: 0px;
    top: 0px;
}
.ChooseImage div{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}