
.authPage{
    background-image: url("../../assets/imgs/bg.jpg");
    height: 100vh;
}
.authPage .overlay{
    background-color: rgba(0,0,0,.7);
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-top: 60px;
}
.authBox{
    padding: 40px 20px;
    text-align: center;
    margin: auto;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .authBox{
        width: 75%;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .authBox{
        width: 70%;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .authBox{
        width: 70%;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .authBox{
        width: 50%;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .authBox{
        width: 30%;
    }
}
.authBox button{
    width: 100%;
}
